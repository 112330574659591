import dayjs from "dayjs"
import { Link } from "gatsby"
import React from "react"
import Date from "./date"
import EyeCatch from "./eye_catch"
import { content, titleStyle, wrapper } from "./post.module.sass"
import TagLink from "./tag_link"

const Post = ({ slug, title, date, tags }) => (
  <Link to={slug} key={slug}>
    <div className={wrapper}>
      <EyeCatch tags={tags} size="medium" />
      <div className={content}>
        <p className={titleStyle}>{title}</p>
        <Date date={dayjs(date).format("YYYY-MM-DD")} />
        {tags.map((tag) => {
          return <TagLink>{tag}</TagLink>
        })}
      </div>
    </div>
  </Link>
)

export default Post
