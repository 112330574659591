import { graphql } from "gatsby"
import React from "react"
import Bio from "../components/bio"
import FeatureTags from "../components/feature_tags"
import Layout from "../components/layout"
import Post from "../components/post"
import Section from "../components/section"

const Index = ({ data }) => (
  <Layout>
    <Section>
      <Bio />
    </Section>
    <Section noPaddingTop>
      <FeatureTags />
    </Section>
    {data.allMdx.edges.map(({ node }) => {
      return (
        <Post
          slug={node.fields.slug}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          tags={node.frontmatter.tags}
        />
      )
    })}
  </Layout>
)

export default Index
export const pageQuery = graphql`
  query {
    allMdx(limit: 1000, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            title
            date
            tags
          }
        }
      }
    }
  }
`
